<template>
  <div class="organization-container">
    <div class="form-content">
      <div class="head-nav fs-14">
        <label class="fc-1 back-title" @click="back">返回上一页</label>
        <label class="fc-9">新增机构数据 /</label>
      </div>
      <div class="title fs-16 fc-1">新增机构数据</div>
      <el-form ref="form" :model="form" :rules="rules">
        <el-form-item prop="community_count">
          <label for="community_count">
            机构覆盖社区数量
            <span>*</span>
          </label>
          <el-input type="number" v-model="form.community_count" maxlength="100" width="200"></el-input>
        </el-form-item>
        <el-form-item prop="help_family_count">
          <label>
            义仓帮扶家庭数量
            <span>*</span>
          </label>
          <el-input type="number" v-model="form.help_family_count" maxlength="100"></el-input>
        </el-form-item>
        <el-form-item prop="in_home_count">
          <label>
            义仓入户场次
            <span>*</span>
          </label>
          <el-input type="number" v-model="form.in_home_count" maxlength="100"></el-input>
        </el-form-item>
        <el-form-item prop="supplies_count">
          <label>
            义仓物资数量
            <span>*</span>
          </label>
          <el-input type="number" v-model="form.supplies_count" maxlength="100"></el-input>
        </el-form-item>
        <el-form-item prop="employee_count">
          <label>
            义工志愿者人数
            <span>*</span>
          </label>
          <el-input type="number" v-model="form.employee_count" maxlength="100"></el-input>
        </el-form-item>
        <el-form-item prop="one_rice_count">
          <label>
            一勺米活动场次
            <span>*</span>
          </label>
          <el-input type="number" v-model="form.one_rice_count" maxlength="100"></el-input>
        </el-form-item>
        <el-form-item prop="one_audience_count">
          <label>
            一个观众的剧场活动场次
            <span>*</span>
          </label>
          <el-input type="number" v-model="form.one_audience_count" maxlength="100"></el-input>
        </el-form-item>
        <el-form-item prop="duty_set_count">
          <label>
            社区义集场次
            <span>*</span>
          </label>
          <el-input type="number" v-model="form.duty_set_count" maxlength="100"></el-input>
        </el-form-item>
        <div class="btns">
          <div class="btn fs-12" @click="onSubmit('form')">确认保存</div>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
import request from "@/utils/request";
import { API, REPORTS_DATA } from "@/const/const";

export default {
  name: "dataForm",
  data() {
    return {
      id: null, // 如果有id表示本月已有数据，后续走update方法
      form: {
        community_count: "",
        help_family_count: "",
        in_home_count: "",
        supplies_count: "",
        employee_count: "",
        one_rice_count: "",
        one_audience_count: "",
        duty_set_count: ""
      },
      rules: {
        community_count: [
          { required: true, message: "请输入机构覆盖社区数量", trigger: "blur" }
        ],
        help_family_count: [
          { required: true, message: "义仓帮扶家庭数量", trigger: "blur" }
        ],
        in_home_count: [
          { required: true, message: "义仓入户场次", trigger: "blur" }
        ],
        supplies_count: [
          { required: true, message: "义仓物资数量", trigger: "blur" }
        ],
        employee_count: [
          { required: true, message: "义工志愿者人数", trigger: "blur" }
        ],
        one_rice_count: [
          { required: true, message: "一勺米活动场次", trigger: "blur" }
        ],
        one_audience_count: [
          { required: true, message: "一个观众的剧场活动场次", trigger: "blur" }
        ],
        duty_set_count: [
          { required: true, message: "社区义集场次", trigger: "blur" }
        ]
      }
    };
  },
  mounted() {
    this.getReportsData();
  },
  methods: {
    getReportsData() {
      const info = JSON.parse(sessionStorage.getItem("userInfo") || "{}");
      if (info.organization && info.organization.id) {
        request.get(API.REPORTS_DATA).then(res => {
          if (res.current_month) {
            // 有current_month数据时证明之前录入过数据，走update流程
            this.id = info.organization.report.id;
          }
          const {
            community_count = "",
            help_family_count = "",
            in_home_count = "",
            supplies_count = "",
            employee_count = "",
            one_rice_count = "",
            one_audience_count = "",
            duty_set_count = ""
          } = res.current_month;
          this.form = {
            community_count,
            help_family_count,
            in_home_count,
            supplies_count,
            employee_count,
            one_rice_count,
            one_audience_count,
            duty_set_count
          };
        });
      }
    },
    back() {
      this.$router.back();
    },
    onSubmit(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          if (this.id) {
            this.updateReportData();
          } else {
            this.postReportData();
          }
        } else {
          this.$message.error("请填写完整表单");
        }
      });
    },
    postReportData() {
      request.post(API.REPORTS_DATA, { report: this.form }).then(res => {
        this.alertMsg();
      });
    },
    updateReportData() {
      request
        .put(API.REPORTS_DATA + `/${this.id}`, { report: this.form })
        .then(res => {
          this.alertMsg();
        });
    },
    alertMsg() {
      this.$alert("提交成功", "提示", {
        confirmButtonText: "确定",
        callback: action => {
          this.$router.go(-1);
        }
      });
    }
  }
};
</script>

<style lang="less" scoped>
.icon-class {
  font-size: 20px;
}
.organization-container {
  background-color: #eee;
  padding: 0.32rem 3rem;
  .form-content {
    background-color: #fff;
    border-radius: 0.01rem;
    padding: 0 1.6rem;
    padding-bottom: 0.56rem;
    position: relative;
    .head-nav {
      position: absolute;
      left: 0.32rem;
      top: 0.16rem;
      .back-title {
        cursor: pointer;
        padding-right: 0.2rem;
      }
    }
    .title {
      text-align: center;
      font-weight: 500;
      padding: 0.48rem 0 0.35rem;
    }
    .btns {
      justify-content: center;
      padding-top: 0.1rem;
      .btn {
        margin: 0 auto;
        width: 1.6rem;
        text-align: center;
        padding: 0.06rem 0.56rem;
        background-color: #efefef;
        border-radius: 0.02rem;
        cursor: pointer;
        background-color: var(--common-red);
        color: #fff;
      }
    }
  }
}
i {
  font-size: 0.4rem !important;
}
.el-message {
  .el-message__icon {
    font-size: 0.3rem !important;
  }
}
/deep/.el-message__icon.el-icon-error {
  font-size: 0.3rem;
}
span {
  font-size: 0.12rem;
  color: #ff3f3d;
}
</style>