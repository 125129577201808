<template>
  <div class="index-container">
    <div class="head">
      <yicangHead class="yicang-head"></yicangHead>
      <headNav class="head-nav"></headNav>
    </div>

    <div class="body">
      <organizationDataPage></organizationDataPage>
    </div>

    <div class="footer">
      <footerBox></footerBox>
    </div>
  </div>
</template>

<script>
// head
import yicangHead from "../components/header/head";
import headNav from "../components/header/headNav";
// main
import organizationDataPage from "../components/container/mine/dataForm";
// footer
import footerBox from "../components/footers/index";

export default {
  name: "OrganizationData",
  components: {
    yicangHead,
    headNav,
    organizationDataPage,
    footerBox
  },
  methods: {
  }
};
</script>
